import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "../login-page/login-page";
import { createBrowserHistory } from "history";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MainMenu from "../../components/main-menu/main-menu";
import Copyright from "../../components/copyright";
import { useStyles } from "../../style/dashboard.styles";
import PrivateRoute from "../../components/private-route";
import { useDispatch } from "react-redux";
import { setDrawerIsOpen } from "../../ducks/ui";
import { useSelector } from "../../ducks/root-reducer";
import { Link } from "react-router-dom";
import { strings } from "../../modules/strings/strings";

// Pages
import SamplesPage from "../samples-page/samples-page";
import MapPage from "../map-page/map-page";
import EventsPage from "../events-page/events-page";
import SpeciesPage from "../species-page/species-page";
import OrdersPage from "../orders-page/orders-page";
import ImportsPage from "../imports-page/imports-page";

// Detail pages
import SpeciesDetailPage from "../species-detail-page/species-detail-page";
import SamplesTissueDetailPage from "../samples-detail-page/samples-tissue-detail-page";
import SamplesIncidentalDetailPage from "../samples-detail-page/samples-incidental-detail-page";
import SamplesVoucherDetailPage from "../samples-detail-page/samples-voucher-detail-page";
import EventsDetailPage from "../events-detail-page/events-detail-page";
import OrderDetailPage from "../orders-detail-page/orders-detail-page";
import { checkAuthenticated, logout, selectUser } from "../../ducks/user";
import ImportsDetailPage from "../imports-detail-page/imports-detail-page";

export const history = createBrowserHistory();
const { app, buttons } = strings;

function App() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const drawerIsOpen = useSelector(s => s.ui.drawerIsOpen);
    const user = useSelector(selectUser);

    const getAdminPathName = () => {
        let baseAdminUrl: string = "http://localhost:8000/admin";
        // NOTE: might be better to set this in the pipeline
        if (process.env.REACT_APP_BRANCH_TAG === "main") {
            baseAdminUrl = "https://rbg-rnr-database.visualise.today/admin/";
        } else if (process.env.REACT_APP_BRANCH_TAG === "staging") {
            baseAdminUrl = "https://rbg-rnr-django.staging1.ixchosted.com/admin/";
        }

        return baseAdminUrl;
    };

    const handleDrawerOpen = () => {
        dispatch(setDrawerIsOpen(true));
    };
    const handleDrawerClose = () => {
        dispatch(setDrawerIsOpen(false));
    };

    useEffect(() => {
        dispatch(checkAuthenticated());
    }, [dispatch]);

    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact>
                    <Login />
                </Route>
                <Route path="/login" exact>
                    <Login />
                </Route>
                <PrivateRoute path="/dashboard/">
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="absolute"
                            className={clsx(classes.appBar, drawerIsOpen && classes.appBarShift)}
                        >
                            <Toolbar className={classes.toolbar}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    className={clsx(
                                        classes.menuButton,
                                        drawerIsOpen && classes.menuButtonHidden
                                    )}
                                    size="large"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Link
                                    to={{ pathname: "/dashboard/samples", state: "samples" }}
                                    className={classes.title}
                                >
                                    <Typography component="h1" variant="h6" noWrap>
                                        {app.navTitle}
                                    </Typography>
                                </Link>
                                <IconButton
                                    color="inherit"
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    style={{ cursor: "default" }}
                                    size="large"
                                >
                                    <Typography style={{ fontStyle: "italic" }}>
                                        {user && user.fullName}
                                    </Typography>
                                </IconButton>
                                {user?.isSuperuser && (
                                    <IconButton color="inherit">
                                        <Link
                                            to={{ pathname: getAdminPathName() }}
                                            target="_blank"
                                            style={{ color: "#FFF", textDecoration: "none" }}
                                        >
                                            <Typography>{buttons.labels.admin}</Typography>
                                        </Link>
                                    </IconButton>
                                )}
                                <IconButton
                                    color="inherit"
                                    onClick={() => {
                                        dispatch(logout());
                                    }}
                                    size="large"
                                >
                                    <Typography>{buttons.labels.logout}</Typography>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            classes={{
                                paper: clsx(
                                    classes.drawerPaper,
                                    !drawerIsOpen && classes.drawerPaperClose
                                ),
                            }}
                            open={drawerIsOpen}
                        >
                            <div className={classes.toolbarIcon}>
                                <Link to={{ pathname: "/dashboard/samples", state: "samples" }}>
                                    <img
                                        src="/img/rnr-banner.png"
                                        height={80}
                                        width={"auto"}
                                        alt="Restore and Renew Logo"
                                    ></img>
                                </Link>
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <MainMenu />
                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.appBarSpacer} />
                            <Container maxWidth="lg" className={classes.container}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper
                                            className={classes.paper}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                minHeight: 700,
                                            }}
                                        >
                                            <Route path="/dashboard" exact>
                                                <Redirect to="/dashboard/samples" />
                                            </Route>
                                            <Route path="/dashboard/samples" exact>
                                                <SamplesPage />
                                            </Route>
                                            <Route
                                                path="/dashboard/samples/tissue/:id"
                                                children={<SamplesTissueDetailPage />}
                                            ></Route>
                                            <Route
                                                path="/dashboard/samples/voucher/:id"
                                                children={<SamplesVoucherDetailPage />}
                                            ></Route>
                                            <Route
                                                path="/dashboard/samples/incidental-observation/:id"
                                                children={<SamplesIncidentalDetailPage />}
                                            ></Route>
                                            <Route path="/dashboard/map">
                                                <MapPage />
                                            </Route>
                                            <Route path="/dashboard/events" exact>
                                                <EventsPage />
                                            </Route>
                                            <Route
                                                path="/dashboard/events/:id"
                                                children={<EventsDetailPage />}
                                            ></Route>
                                            <Route path="/dashboard/species" exact>
                                                <SpeciesPage />
                                            </Route>
                                            <Route
                                                path="/dashboard/species/:id"
                                                children={<SpeciesDetailPage />}
                                            ></Route>
                                            <Route path="/dashboard/orders" exact>
                                                <OrdersPage />
                                            </Route>
                                            <Route
                                                path="/dashboard/orders/:id"
                                                children={<OrderDetailPage />}
                                            ></Route>
                                            <Route path="/dashboard/import" exact>
                                                <ImportsPage />
                                            </Route>
                                            <Route
                                                path="/dashboard/import/:id"
                                                children={<ImportsDetailPage />}
                                            ></Route>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Box pt={4}>
                                    <Copyright />
                                </Box>
                            </Container>
                        </main>
                    </div>
                </PrivateRoute>
            </Switch>
        </Router>
    );
}

export default App;
