import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DartOrderDetailSpecies, DartOrderDetailSample, DArTAnalysisStatus } from "../../types";
import Checkbox from "@material-ui/core/Checkbox";
import { dartAnalysisStatuses } from "../../modules/listItemsFromEnums";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useWellPlateStyles } from "./well-plate-styles";
import { setAnalysisStatusForSpecies, setIsFinalOrderForSpecies } from "./well-plate-helpers";
import { SetPatchFunction } from "../../hooks/useDetailForm";
import { orderSummaryTableCols, DartOrderDetailSpeciesKey } from "./well-plate-config";

type SpeciesTableProps = {
    id: string; // Service number
    samples: DartOrderDetailSample[];
    species: DartOrderDetailSpecies[];
    setPatchValue: SetPatchFunction;
};

function SpeciesTable({ species, samples, id, setPatchValue }: SpeciesTableProps) {
    const classes = useWellPlateStyles();
    const theme = useTheme();

    function keyToValue(
        key: DartOrderDetailSpeciesKey,
        species: DartOrderDetailSpecies,
        samples: DartOrderDetailSample[]
    ): string | number {
        switch (key) {
            case "numIndividuals":
                return samples.reduce((acc, curr) => {
                    return curr.taxonKey === species.taxonKey ? acc + 1 : acc;
                }, 0);
            default:
                return species[key] as string;
        }
    }

    function onSetIsFinalOrder(
        speciesToUpdate: DartOrderDetailSpecies,
        isFinalOrder: boolean,
        allSpecies: DartOrderDetailSpecies[],
        allSamples: DartOrderDetailSample[]
    ): void {
        const { samples, species } = setIsFinalOrderForSpecies(
            speciesToUpdate,
            isFinalOrder,
            allSpecies,
            allSamples
        );
        setPatchValue(
            "sampleAndSpecies",
            {
                samples: samples,
                species: species,
            },
            id
        );
    }

    function onSetAnalysisStatus(
        speciesToUpdate: DartOrderDetailSpecies,
        analysisStatus: DArTAnalysisStatus,
        allSpecies: DartOrderDetailSpecies[],
        allSamples: DartOrderDetailSample[]
    ): void {
        const { samples, species } = setAnalysisStatusForSpecies(
            speciesToUpdate,
            analysisStatus,
            allSpecies,
            allSamples
        );
        setPatchValue(
            "sampleAndSpecies",
            {
                samples: samples,
                species: species,
            },
            id
        );
    }

    function keyToCellContents(
        key: DartOrderDetailSpeciesKey,
        species: DartOrderDetailSpecies,
        allSpecies: DartOrderDetailSpecies[],
        allSamples: DartOrderDetailSample[]
    ): JSX.Element {
        const innerText = keyToValue(key, species, samples);
        if (key === "isFinalOrder") {
            return (
                <Checkbox
                    checked={species.isFinalOrder}
                    onChange={() => {
                        onSetIsFinalOrder(species, !species.isFinalOrder, allSpecies, allSamples);
                    }}
                    style={{ color: theme.palette.info.main, padding: 3 }}
                />
            );
        } else if (key === "analysisStatus") {
            return (
                <div className="species-form-control">
                    <FormControl variant="standard" className={classes.speciesDropdownFormControl}>
                        <Select
                            value={species.analysisStatus || ""}
                            onChange={e => {
                                // The backend needs 'null' to clear the status, but the dropdown doesn't like it
                                // So we convert back and forth, null <=> "-"
                                const val = (
                                    e.target.value === "-" ? null : e.target.value
                                ) as DArTAnalysisStatus;
                                onSetAnalysisStatus(species, val, allSpecies, allSamples);
                            }}
                            label={key}
                        >
                            {dartAnalysisStatuses.map((m, i) => {
                                return (
                                    <MenuItem
                                        value={m.label || ""}
                                        key={m.key || ""}
                                        style={{
                                            padding: 5,
                                            color:
                                                m.label === "-"
                                                    ? "white"
                                                    : theme.palette.text.primary,
                                        }}
                                    >
                                        {m.label || ""}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            );
        }
        return <span>{innerText}</span>;
    }

    function totalRowKeyToCellContents(
        key: DartOrderDetailSpeciesKey,
        allSpecies: DartOrderDetailSpecies[],
        allSamples: DartOrderDetailSample[]
    ): JSX.Element {
        switch (key) {
            case "acceptedName":
                return <span>Total</span>;
            case "numIndividuals":
                return <span>{allSamples.length}</span>;
            default:
                return <span></span>;
        }
    }

    return (
        <div>
            {species && (
                <TableContainer className={classes.container}>
                    <Table size="small" stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                {orderSummaryTableCols.map((col, i) => {
                                    return (
                                        <TableCell key={i} className={classes.headerCell}>
                                            {col.label}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {species.map(s => {
                                return (
                                    <TableRow key={s.taxonKey}>
                                        {orderSummaryTableCols.map(col => {
                                            return (
                                                <TableCell key={col.key}>
                                                    {keyToCellContents(
                                                        col.key,
                                                        s,
                                                        species,
                                                        samples
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            <TableRow key={"total"}>
                                {orderSummaryTableCols.map((col, i) => {
                                    return (
                                        <TableCell key={`tot-${i}`} style={{ fontWeight: "bold" }}>
                                            {totalRowKeyToCellContents(col.key, species, samples)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default SpeciesTable;
