import { DetailPageSection } from "../../types";
import { dartServices } from "../../modules/listItemsFromEnums";
import { ensureUniqUpdatedId } from "../../modules/validation/checkValidId";

export const orderForm: DetailPageSection[] = [
    {
        sectionTitle: "Create new order",
        fields: [
            {
                label: "Service number",
                key: "serviceNumber",
                isReadOnly: false,
                inputType: "textfield",
                validate: (newId, existingId) =>
                    ensureUniqUpdatedId("dart-order", newId, existingId),
            },
            {
                label: "Service",
                key: "service",
                isReadOnly: false,
                inputType: "filter",
                options: dartServices,
            },
            {
                label: "Project",
                key: "projectKey",
                isReadOnly: false,
                inputType: "autocomplete",
                optionsKey: "projectList",
            },
            { label: "Date sent", key: "dateSent", isReadOnly: false, inputType: "datepicker" },
            {
                label: "Date received",
                key: "dateReceived",
                isReadOnly: false,
                inputType: "datepicker",
            },
        ],
    },
];
