import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { api, DetailPath } from "../modules/api";
import {
    DartOrderDetailSpecies,
    DataError,
    SavingStatus,
    DetailPageSection,
    DetailKey,
} from "../types";
import { getFieldByKey, detailPathToKey } from "../modules/detail-page-helpers/detail-page-helpers";

// useDetailForm takes input describing :
// ID (NSWnumber etc) of the entity, URLs for GET and PATCH, fields and their types/validation
// (as well as potential values for dropdowns)
// And returns a DetailForm type

// if revert changes, then set eventPatch to {}
// TODO: deep merge for nested objects.
// const eventWithPatches = React.useMemo(() => ({
//     ...activeEvent,
//     ...eventPatch,
// }), [activeEvent, eventPatch]);

export type SetPatchFunction = (key: string, value: any, id: string, parentKey?: string) => void;

export function useDetailForm<DisplayType, PatchType>(
    id: string,
    getPath: DetailPath,
    patchPath: DetailPath,
    sections: DetailPageSection[],
    updateContiniously?: boolean
) {
    // RETURN TYPE
    type DetailForm = {
        displayData: DisplayType; //(a merge of the fetched data, and our edited data. Memo this. This is what your form should display)
        patchData: PatchType; // (an object with just the changed fields)
        undoUnsavedChanges: () => void;
        setPatchValue: SetPatchFunction; // (function to setState for patch data)
        submit: (id: string, patch: PatchType) => Promise<SavingStatus>; //(function to send patch request that doesn't resolve till submitted
        errors: Map<DetailKey, DataError>;
        savingStatus: SavingStatus;
    };

    // STATE
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState<any>({});
    const [patchData, setPatchData] = useState<any>({});
    const [errors, setErrors] = useState<Map<DetailKey, DataError>>(new Map());
    const [savingStatus, setSavingStatus] = useState<SavingStatus>({
        savingInProgress: false,
        savingError: { error: false },
        idStatus: { changed: false },
    });

    // Set form data based on the ID and path
    useEffect(() => {
        const load = () =>
            loadData(getPath, id).then(entity => {
                setDisplayData(entity);
                setInitialPatchState(entity);

                // Now check all fields are valid on intial load
                Object.entries(entity).forEach(([key, value]) => {
                    const validate = getFieldByKey(key as DetailKey, sections)?.validate;
                    if (validate) {
                        validate(value, id).then(resp => {
                            setErrors(e => new Map(e.set(key as DetailKey, resp)));
                        });
                    }
                });
            });

        load();

        if (updateContiniously) {
            const interval = setInterval(() => {
                load();
            }, 2000);

            return () => clearInterval(interval);
        }
    }, [dispatch, id, getPath, sections, updateContiniously]);

    const loadData = async (getPath: string, id: string): Promise<DisplayType> => {
        const response = await api(`${getPath}/${id}`);

        return await response.json();
    };

    // UNDO UNSAVED CHANGES
    const undoUnsavedChanges = () => {
        loadData(getPath, id).then(entity => {
            setDisplayData(entity);
            setInitialPatchState(entity);

            // Validate again
            Object.entries(entity).forEach(([key, value]) => {
                validateValue(key as DetailKey, value, id);
            });
        });
    };

    const setInitialPatchState = (entity: DisplayType) => {
        // Bit of a hack.
        // Need to make sure that sample and species are always part of the patch for DArT orders
        const { samples, species } = entity as any;
        if (samples && species) {
            setPatchData({
                samples: samples,
                species: species,
            });
        } else {
            setPatchData({});
        }
    };

    // PATCH DATA
    const sendPatch = async (id: string, patch: PatchType): Promise<SavingStatus> => {
        // Make sure we don't send null samples to the DB
        let sanitisedPatch = { ...patch };
        if ((patch as any).samples) {
            sanitisedPatch = {
                ...patch,
                samples: (patch as any).samples.filter((s: any) => s.plateNumber),
            };
        }

        // Send patch
        // Check if the ID has been changed (it can be edited on the order page)
        const displayIdKey = detailPathToKey(getPath);

        const displayId = displayData[displayIdKey];
        const idHasChanged = displayId !== id;
        setSavingStatus({ savingInProgress: true, savingError: { error: false } });

        const res = await api(
            `${patchPath}/${id}/`,
            {
                method: "PATCH",
                body: JSON.stringify(sanitisedPatch),
                headers: {
                    "Content-Type": "application/json",
                },
            },
            true
        );
        if (res.ok) {
            const status: SavingStatus = {
                savingInProgress: false,
                savingError: { error: false, message: "Saved!" },
                idStatus: { changed: idHasChanged, newId: idHasChanged ? displayId : id },
            };
            setSavingStatus(status);
            setInitialPatchState(displayData);
            return status;
        } else {
            const msg = `Saving failed${res.status ? `: ${res.status} ${res.statusText}` : ""}`;
            const status: SavingStatus = {
                savingInProgress: false,
                savingError: { error: true, message: msg },
                idStatus: { changed: idHasChanged, newId: idHasChanged ? displayId : id },
            };
            setSavingStatus(status);
            setInitialPatchState(displayData);
            return status;
        }
    };

    // VALIDATE
    const validateValue = (key: DetailKey, value: any, id: string): void => {
        const validate = getFieldByKey(key, sections)?.validate;
        if (validate) {
            validate(value, id).then(resp => {
                setErrors(e => new Map(e.set(key, resp)));
            });
        }
    };

    // Function to setState for patch data
    function setPatchValue(key: DetailKey, value: any, id: string, parentKey?: string): void {
        // Validation
        validateValue(key, value, id);

        // PATCH DATA
        // Reidentification
        let newValue = value;
        if (["taxonKey", "dateIdentified", "identificationRemarks", "identifiedBy"].includes(key)) {
            setPatchData({
                ...patchData,
                identification: { ...patchData?.identification, [key]: newValue },
            } as PatchType);
        }
        // "sampleAndSpecies" is a special frontend key
        // Because dart-order samples and species must be updated at the same time on backend
        // Frontend species have more info than the backend requires
        else if (key === "sampleAndSpecies") {
            const patchSpecies = value.species.map((s: DartOrderDetailSpecies) => {
                const { acceptedName, genus, species, ...rest } = s;
                return rest;
            });
            setPatchData({
                ...patchData,
                samples: value.samples,
                species: patchSpecies,
            } as PatchType);
        } else if (parentKey) {
            setPatchData({
                ...patchData,
                [parentKey]: patchData[parentKey]
                    ? { ...patchData[parentKey], [key]: newValue }
                    : { [key]: newValue },
            } as PatchType);
        } else {
            setPatchData({
                ...patchData,
                [key]: newValue,
            } as PatchType);
        }

        // DISPLAY DATA
        if (parentKey) {
            setDisplayData({
                ...displayData,
                ...(displayData[parentKey] && {
                    [parentKey]: { ...displayData[parentKey], [key]: newValue },
                }),
            });
        }
        // "sampleAndSpecies" is a special frontend key
        // Because dart-order samples and species must be updated at the same time on backend
        else if (key === "sampleAndSpecies") {
            setDisplayData({
                ...displayData,
                samples: value.samples,
                species: value.species,
            } as DisplayType);
        } else {
            setDisplayData({
                ...displayData,
                [key]: newValue,
            } as DisplayType);
        }
    }

    return {
        displayData: displayData as DisplayType,
        patchData: patchData as PatchType,
        undoUnsavedChanges: undoUnsavedChanges,
        setPatchValue: setPatchValue,
        submit: sendPatch,
        errors: errors,
        savingStatus: savingStatus,
    } as DetailForm;
}
