import { useState, useEffect } from "react";
import { numberToPlatePosition } from "../modules/numberToCoords";
import { DartOrderPatchSample, DartOrderDetailSpecies, TissueSampleDetail } from "../types";
import { api } from "../modules/api";
import { PlateOptions, WELL_PLATE_COLORS } from "../components/well-plate/well-plate-config";

export type SpeciesColors = {
    speciesByColor: any;
    colors: string[];
    currentColorIndex: number;
};

export type SampleSpeciesUpdate = {
    sample: DartOrderPatchSample;
    species: DartOrderDetailSpecies;
};

export type PlateItem = DartOrderPatchSample & {
    organism?: string;
    species?: string;
};

export type PlateItemKey = keyof PlateItem;

// Return type
export type WellPlates = {
    // plateItems: PlateItem[];
    selectedItem: number;
    selectedPlateIndex: number;
    setSelectedItem: (index: number) => void;
    setSelectedPlate: (index: number) => void;
    selectItemAtIndex: (index: number) => PlateItem;
    addPlate: () => void;
    removePlate: (plateIndexToRemove: number) => void;
    getNumPlates: () => number;
    getCurrentPlateIndex: () => number;
    setCurrentPlateIndex: (index: number, opts: PlateOptions) => void;
    incrementPlateIndex: () => void;
    findSampleByNswNumber: (
        id: string,
        index: number,
        options: PlateOptions
    ) => Promise<SampleSpeciesUpdate>;
    getColorForSpecies: (speciesName: string) => string;
};

export function useWellPlates(samples: PlateItem[], speciesList: DartOrderDetailSpecies[]) {
    // Frontend well plate state
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [numPlates, setNumPlates] = useState<number>(1);
    const [selectedPlateIndex, setSelectedPlateIndex] = useState<number>(0);
    const [speciesColors, setSpeciesColors] = useState<SpeciesColors>({
        speciesByColor: {},
        colors: WELL_PLATE_COLORS,
        currentColorIndex: 0,
    });

    // Make sure numplates is correct at initialisation
    // Choose the higher of FE numPlates and highest plate number in samples
    useEffect(() => {
        if (!samples) return;
        const numPlatesBasedOnSamples = samples.reduce((acc, curr) => {
            return curr.plateNumber > acc ? curr.plateNumber : acc;
        }, 1);
        setNumPlates(Math.max(numPlatesBasedOnSamples, numPlates));
    }, [samples, setNumPlates, numPlates]);

    // A whole lot of code to set the colors
    useEffect(() => {
        if (!speciesList) return;
        setSpeciesColors(colorInfo => {
            let x = colorInfo.currentColorIndex;
            const speciesByColor = speciesList.reduce((acc, curr, i) => {
                // If the species already has a color, return it
                if (colorInfo.speciesByColor[curr.taxonKey]) {
                    return { ...acc };
                } else {
                    const newColors = {
                        ...acc,
                        [curr.taxonKey]: colorInfo.colors[x % colorInfo.colors.length],
                    };
                    x = (x + 1) % 10;
                    return newColors;
                }
            }, colorInfo.speciesByColor);
            return {
                ...colorInfo,
                speciesByColor: speciesByColor,
                currentColorIndex: x,
            };
        });
    }, [speciesList]);

    // GET DATA FOR GENOTYPE
    const findSampleByNswNumber = async (
        id: string,
        index: number,
        options: PlateOptions
    ): Promise<SampleSpeciesUpdate | undefined> => {
        const res = await api(`tissue-sample/${id}`);

        if (!res.ok) {
            return undefined;
        } else {
            const data = (await res.json()) as TissueSampleDetail;
            const { row, column, plateNumber } = numberToPlatePosition(
                index,
                options.numRows,
                options.numCols,
                numPlates
            );
            const newPlateItem: DartOrderPatchSample = {
                taxonKey: data.taxonKey || "",
                nswNumber: data.NSWnumber,
                row: row,
                column: column,
                plateNumber: plateNumber,
                inPlate: false,
            };
            const newSpecies: DartOrderDetailSpecies = {
                taxonKey: data.taxonKey || "",
                isFinalOrder: false,
                analysisStatus: null,
                acceptedName: data.acceptedName || "",
                genus: data.acceptedName?.split(" ")[0] || "",
                species: data.acceptedName?.split(" ")[1] || "",
            };
            return { sample: newPlateItem, species: newSpecies };
        }
    };

    function getColorForSpecies(taxonKey: string): string {
        return speciesColors.speciesByColor[taxonKey];
    }

    function addPlate(): void {
        setNumPlates(numPlates + 1);
    }

    function getNumPlates(): number {
        return numPlates;
    }

    function getCurrentPlateIndex(): number {
        return selectedPlateIndex;
    }

    function incrementPlateIndex(): void {
        setSelectedPlateIndex(selectedPlateIndex + 1);
    }

    function removePlate(plateIndexToRemove: number): void {
        // Decrement plate index
        if (numPlates > 1) {
            setNumPlates(numPlates - 1);
        }
        // Select the previous plate if there is one, otherwise no-op
        if (plateIndexToRemove > 0) {
            setSelectedPlateIndex(getCurrentPlateIndex() - 1);
        }
    }

    function setCurrentPlateIndex(index: number, opts: PlateOptions): void {
        // Selected item can be first item on the new page, when you go to a new page
        const plateSize = opts.numCols * opts.numRows;
        setSelectedItem(index * plateSize);
        setSelectedPlateIndex(index);
    }

    function selectPlate(plateIndex: number): void {
        setSelectedPlateIndex(plateIndex);
    }

    return {
        selectedItem: selectedItem,
        selectedPlateIndex: selectedPlateIndex,
        setSelectedItem: setSelectedItem,
        setSelectedPlate: (index: number) => {
            selectPlate(index);
        },
        addPlate: addPlate,
        removePlate: removePlate,
        getNumPlates: getNumPlates,
        getCurrentPlateIndex: getCurrentPlateIndex,
        setCurrentPlateIndex: setCurrentPlateIndex,
        incrementPlateIndex: incrementPlateIndex,
        findSampleByNswNumber: findSampleByNswNumber,
        getColorForSpecies: getColorForSpecies,
    } as WellPlates;
}
